<template>
	<!-- 头部弹窗 -->
	<div v-if="fuck" @mouseenter="headShow" @mouseleave="headHide" class="heard-top animated faster fadeIn">
		<div v-if="backstage">
			<div class="tab-inex" @click="navTo('userInfo')">个人中心</div>
			<div class="tab-inex" @click="navTo('assets')">账户资产</div>
			<div class="tab-inex" @click="navTo('account')">账号设置</div>
			<div class="tab-inex" @click="navTo('enterprises')">申请名企大厂</div>
			<div class="tab-inex" @click="navTo('Home')">切换求职者</div>
		</div>
		<div v-else>
			<div class="tab-inex" @click="navTo('Personal-center')">个人中心</div>
			<div class="tab-inex" @click="navTo('Privacy')">隐私设置</div>
			<div class="tab-inex" @click="navTo('Recruiter')">切换为招聘者</div>
			<div class="tab-inex" @click="navTo('Elite')">申请成为精英</div>
		</div>
		<div class="tab-inex" @click="logout">退出登录</div>
	</div>
</template>

<script>
	export default {
		props: {
			backstage: {
				type: Boolean,
				default: true,
			},
		},
		components: {},

		data() {
			return {
                fuck: true
            };
		},

		created() {},

		mounted() {},

		methods: {
			navTo(type) {
				this.$emit("navToClick", type);
			},
			headShow() {
                this.fuck = true
				this.$emit("mouseenter");
			},
			headHide() {
                this.fuck = true
				this.$emit("mouseleave");
			},
			// 退出登录
			logout() {
				this.$confirm("此操作将退出登录, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$store.commit("loginOut");
						this.$emit("logout");
						this.$message({
							type: "success",
							message: "退出成功!",
						});
						let promise = this.$tim.logout();
						promise
							.then(function(imResponse) {
								console.log("登出成功"); // 登出成功
							})
							.catch(function(imError) {
								console.warn("logout error:", imError);
							});
					})
					.catch(() => {});
			},
		},
	};
</script>
<style lang="less" scoped>
	.heard-top {
		background: #f2f5fa;
		width: 13.3125rem;

		.tab-inex {
			width: 100%;
			height: 2.9375rem;
			text-align: center;
			line-height: 2.9375rem;
			background: #ffffff;
			margin-bottom: 1px;
			cursor: pointer;
		}

		>.tab-inex:last-child {
			margin-bottom: 0;
			margin-top: 4px;
		}
	}
</style>
